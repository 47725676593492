import React, { useState } from 'react';
import {
  Form,
  Input,
  Card,
  Button,
  Select,
  Typography,
  Divider
} from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { UserOutlined, LinkedinOutlined, MobileOutlined, MailOutlined, TwitterOutlined , EnvironmentOutlined, WalletOutlined } from '@ant-design/icons';
const { Title } = Typography;

export const FormCustomer: React.FC<any> = (data) => {

  const onFinish = (values: any) => {
    console.log('Received values of form: ', values, data, data.setCustomerData);
    data.setCustomerData(values);
  };

  return (
  <Card>
    <Form
      name="customer_form"
      onFinish={onFinish}
      initialValues={data.customerData}
      labelCol={{ span: 8 }}
    >
      <>
        <Divider plain><Title level={4}>Wallet Address</Title></Divider>
        <Form.Item 
          label="Wallet Address" 
          name="wallet_address"
        >
          <Input disabled={true} prefix={<WalletOutlined />}/>
        </Form.Item>
        <Divider plain><Title level={4}>Select an NFT to register</Title></Divider>  
        <Form.Item 
          label="Token Id"
          name="tokenId"
          rules={[{ required: true, message: 'Please select your token' }]}
        >
          <Select
            placeholder="Please select an NFT token id"
            options={
              data.tokenData.map((x:any) => { return { value: x.tokenRecId, label: x.tokenId }})
            }
          />
        </Form.Item>
        <Divider plain><Title level={4}>Personal Information</Title></Divider>
        <Form.Item 
          label="First Name" 
          name="first_name"
          rules={[{ required: true, message: 'Please input your name' }]}
        >
          <Input prefix={<UserOutlined />}/>
        </Form.Item>
        <Form.Item 
          label="Last Name" 
          name="last_name"
          rules={[{ required: true, message: 'Please input your surname' }]}
        >
          <Input prefix={<UserOutlined />}/>
        </Form.Item>
      </>

      <>
        <Divider plain><Title level={4}>Contact Information</Title></Divider>
        <Form.Item 
          label="Mobile number" 
          name="mobile_number"
          rules={[{ required: true, message: 'Please input your mobile number' }]}
        >
          <Input prefix={<MobileOutlined />}/>
        </Form.Item>
        <Form.Item 
          label="Email" 
          name="email"
          rules={[{ required: true, message: 'Please input your email' }]}
        >
          <Input prefix={<MailOutlined />}/>
        </Form.Item>
      </>

      <Divider plain><Title level={4}>Postal Address</Title></Divider>
      <Form.Item 
        label="Street number & address" 
        name="postal_address"
        rules={[{ required: true, message: 'Please input your address.' }]}
      >
        <Input prefix={<EnvironmentOutlined />}/>
      </Form.Item>

      <Divider plain><Title level={4}>Socials</Title></Divider>
      <Form.Item 
          label="LinkedIn" 
          name="linkedin"
        >
        <Input prefix={<LinkedinOutlined />}/>
      </Form.Item>
      <Form.Item 
          label="Twitter" 
          name="twitter"
        >
        <Input prefix={<TwitterOutlined />}/>
      </Form.Item>

      <Divider plain></Divider>
      <Form.Item>
        <Button 
          style={{
            position: "absolute",
            left: 0,
            width: "120px",
            transform: "translateY(-50%)"
          }} 
          onClick={() => data.goPrevious()}
        >
            Previous
        </Button>
        <Button 
          style={{
            position: "absolute",
            right: 0,
            width: "120px",
            transform: "translateY(-50%)"
          }} 
          type="primary" 
          htmlType="submit"
        >
            Next
        </Button>
      </Form.Item>
    </Form>
  </Card>
)};