import React from 'react';
import {
  Form,
  Input,
  Card,
  Button,
  Select,
  Checkbox,
  Typography,
  Divider
} from 'antd';
import { UserOutlined, LinkedinOutlined, MobileOutlined, MailOutlined, TwitterOutlined, EnvironmentOutlined, WalletOutlined } from '@ant-design/icons';
import Link from 'antd/es/typography/Link';
const { Title, Paragraph } = Typography;


const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

export const FormConfirmation: React.FC<any> = (data) => {
  
  const onFinish = (values: any) => {
    console.log('Received values of form: ', values);
    data.goNext();
    data.send();
  };

  console.log('customer data', data.customerData);

  return (
  <>
    <Card>
    <Title level={2}>Confirmation Statement</Title>
      <Divider plain></Divider>
      <Paragraph>
        Please read through the information below in order to confirm that your personal data is accurate.
      </Paragraph>
    </Card>
    <Card>
      <Form
        name="customer_form"
        onFinish={onFinish}
        initialValues={data.customerData}
        labelCol={{ span: 8 }}
        disabled={true}
      >
        <>
          <Divider plain><Title level={4}>Wallet Address</Title></Divider>
          <Form.Item 
            label="Wallet Address" 
            name="wallet_address"
          >
            <Input disabled={true} prefix={<WalletOutlined />}/>
          </Form.Item>
          <Divider plain><Title level={4}>Select an NFT to register</Title></Divider>  
          <Form.Item 
            label="Token Id"
            name="tokenId"
            rules={[{ required: true, message: 'Please select your token' }]}
          >
            <Select
              placeholder="Please select an NFT token id"
              options={
                data.tokenData.map((x:any) => { return { value: x.tokenRecId, label: x.tokenId }})
              }
            />
          </Form.Item>
          <Divider plain><Title level={4}>Personal Information</Title></Divider>
          <Form.Item 
            label="First Name" 
            name="first_name"
            rules={[{ required: true, message: 'Please input your name' }]}
          >
            <Input prefix={<UserOutlined />}/>
          </Form.Item>
          <Form.Item 
            label="Last Name" 
            name="last_name"
            rules={[{ required: true, message: 'Please input your surname' }]}
          >
            <Input prefix={<UserOutlined />}/>
          </Form.Item>
        </>

        <>
          <Divider plain><Title level={4}>Contact Information</Title></Divider>
          <Form.Item 
            label="Mobile number" 
            name="mobile_number"
            rules={[{ required: true, message: 'Please input your mobile number' }]}
          >
            <Input prefix={<MobileOutlined />}/>
          </Form.Item>
          <Form.Item 
            label="Email" 
            name="email"
            rules={[{ required: true, message: 'Please input your email' }]}
          >
            <Input prefix={<MailOutlined />}/>
          </Form.Item>
        </>

        <Divider plain><Title level={4}>Postal Address</Title></Divider>
        <Form.Item 
          label="Street number & address" 
          name="postal_address"
          rules={[{ required: true, message: 'Please input your address.' }]}
        >
          <Input prefix={<EnvironmentOutlined />}/>
        </Form.Item>

        <Divider plain><Title level={4}>Socials</Title></Divider>
        <Form.Item 
            label="LinkedIn" 
            name="linkedin"
          >
          <Input prefix={<LinkedinOutlined />}/>
        </Form.Item>
        <Form.Item 
            label="Twitter" 
            name="twitter"
          >
          <Input prefix={<TwitterOutlined />}/>
        </Form.Item>

      </Form>
    </Card>
    <Card>
      <Title level={4}>Privacy & Confirmation</Title>
      <Divider plain></Divider>
      <Paragraph>
        Please read our <Link href="https://barlulu.com.au/privacy-policy/">Privacy Policy</Link>
      </Paragraph>
      <Form
        name="confirmation_form"
        onFinish={onFinish}
        >
        <Form.Item
          name="privacy"
          valuePropName="checked"
          rules={[{ 
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(new Error('Please confirm that you have read the privacy policy.')),
          }]}
        >
          <Checkbox>Hereby I confirm that I have read the privacy policy.</Checkbox>
        </Form.Item>
      
        <Divider plain></Divider>
        <Paragraph>
          Please read through the information below in order to confirm that your personal data is accurate.
        </Paragraph>
        <Form.Item
          name="confirmation"
          valuePropName="checked"
          rules={[{ 
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(new Error('Please confirm accuracy.')),
          }]}
        >
          <Checkbox>Hereby I confirm the information is accurate</Checkbox>
        </Form.Item>
        
        <Form.Item>
          <Button 
            style={{
              position: "absolute",
              width: "120px",
              left: 0,
              transform: "translateY(-50%)"
            }} 
            onClick={() => data.goPrevious()}
            disabled={false}
          >
              Previous
          </Button>
          <Button 
            style={{
              position: "absolute",
              right: 0,
              width: "120px",
              transform: "translateY(-50%)"
            }} 
            type="primary" 
            disabled={false}
            htmlType="submit"
          >
              Next
          </Button>
        </Form.Item>
      </Form>
    </Card>
  </>
)};