import React, { useState, useEffect } from 'react';
import './index.css';
import { Image, Col, Layout, Steps, theme, Result, Button, Card, Typography, Divider, notification, Spin } from 'antd';

import { FormCustomer } from './components/customer'
import { FormConfirmation } from './components/confirmation'

import { LoadingOutlined } from '@ant-design/icons';

import emailjs from '@emailjs/browser';

import axios from 'axios';

import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import CryptoLulu from './abi/CryptoLulu.sol/CryptoLulu.json';
import { Buffer } from 'buffer';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


const { Header, Content } = Layout;
const { Title, Paragraph, Link } = Typography;

const emailjs_service_id = '';
const emailjs_template_id = '';
const emailjs_template_public_key = '';

const feltArrToStr = (felts: bigint[]): string => {
  return felts.reduce(
      (memo, felt) => memo + Buffer.from(felt.toString(16), "hex").toString(),
      ""
  );
}
const strToFeltArr = (str: string): BigInt[] => {
  const size = Math.ceil(str.length / 31);
  const arr = Array(size);

  let offset = 0;
  for (let i = 0; i < size; i++) {
      const substr = str.substring(offset, offset + 31).split("");
      const ss = substr.reduce(
      (memo, c) => memo + c.charCodeAt(0).toString(16),
      ""
      );
      arr[i] = BigInt("0x" + ss);
      offset += 31;
  }
  return arr;
}
const App: React.FC = () => {
  const { token } = theme.useToken();
  const [isLoading, setLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  const [tokenIdData, setTokenIdData] = useState<any>([]);
  const [customerData, setCustomerData] = useState<any>({});

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type: NotificationType, message: string, description: string) => {
    api[type]({
      message: message,
      description: description
    });
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const captureCustomerData = (data: any) => {
    console.log(data);
    setCustomerData(data);
    next();
  }

  const web3Login = () => {
    setLoading(true);
    try{
      const { ethereum } = window as any;
      const web3 = new Web3(ethereum);
      ethereum
      .request({
          method: "eth_requestAccounts",
      })
      .then(async (accounts : string[]) => {
        console.log('setting wallet', accounts[0])

        let counterpart = accounts[0];
        console.log(counterpart)
        
        setCustomerData({wallet_address: counterpart });

        const cryptoLulu = new web3.eth.Contract(CryptoLulu.abi as AbiItem[], '0xaD4D20A6ccC4c0597E4206e6ce52D80A9EC6c44d');

        let bal = await cryptoLulu.methods.balanceOf(counterpart).call();
        if(bal > 0){

          let tokenIds = await cryptoLulu.getPastEvents('Transfer', { fromBlock: '0x0', filter: { to: counterpart } })
            .then((res) => {
              return res
                .filter((rec) => rec.returnValues.to.toLowerCase() == counterpart.toLowerCase())
                .map((rec) => {
                  const tokenId: bigint = rec.returnValues.tokenId;
                  const tokenIdName = feltArrToStr([BigInt(tokenId.toString())])
                  console.log('tok', tokenId, tokenIdName);
                  return tokenId;
                });
            })
            .catch(err => console.log(err))

          if(tokenIds){
            let owners = await Promise.all(tokenIds.map(async tokenId => {
              let owner = await cryptoLulu.methods.ownerOf(tokenId).call();
              let tokenRecId = feltArrToStr([BigInt(tokenId.toString())])
              return { tokenId: tokenId, owner: owner.toLowerCase(), tokenRecId: tokenRecId };
            }));

            let filteredOwners = owners.filter(x => x.owner == counterpart.toLowerCase());

            setTokenIdData(filteredOwners);

            if(filteredOwners.length > 0)
              next();
            else
              openNotificationWithIcon('error', "No Memberships", "We couldn't find any membership NFTs linked to this wallet");
          }

        }
        else
          openNotificationWithIcon('error', "No Memberships", "We couldn't find any membership NFTs linked to this wallet");

      })
      .catch((error: any) => {
        openNotificationWithIcon('error', "Login", "Error with your login");
      });
    }
     catch (error: any) {
      openNotificationWithIcon('error', "Wallet", "We are not able to find your wallet extension");
    };

    setLoading(false);
  }

  const useCheckMobileScreen = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
            setWidth(window.innerWidth);
    }
  
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
  
    return (width < 768);
  }

  const send = () => {
  //   emailjs.send(emailjs_service_id, emailjs_template_id, {
  //     message: JSON.stringify({ personal: customerData}, null, 4)

  // } , emailjs_template_public_key)
  //   .then((result) => {
  //       console.log(result.text);
  //   }, (error) => {
  //       console.log(error.text);
  //   });
  
  axios
    .post(
      "https://nc-cors.azurewebsites.net/api/airtable-lulu-register?code=TKdb5-hQ7VzSgOSkU3o1H1-agLK9KzgpVKZWPS75_nwhAzFu7UznzA==", 
      {
        
        'id': customerData.tokenId.toString(),
        'wallet': customerData.wallet_address,
        'first_name': customerData.first_name,
        'last_name': customerData.last_name,
        'email': customerData.email,
        'mobile_number': customerData.mobile_number,
        'linkedin': customerData.linkedin,
        'twitter': customerData.twitter,
        'postal_address': customerData.postal_address,
        'token_id': strToFeltArr(customerData.tokenId)[0].toString()
      }, 
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        }
      }
    )
    .then((val) => { 
      console.log(val);
      
    })
    .catch(err => {
      console.log(err)
      openNotificationWithIcon('error', "Error", "Error submitting message, please contact Crypto Lulu");
    });
  }

  const isMobile = useCheckMobileScreen()
  const spanValue = isMobile ? 24 : 16;
  const offsetValue = isMobile ? 0 : 4;

  const steps = [
    {
      title: 'Start',
      content: 
        <Col span={spanValue} offset={offsetValue}>
          <Card>
            <Image
              width={150}
              src="/Logo.png"
            />
            <Title level={2}>Congratulations on purchasing a CryptoLulu NFT!</Title>
            <Divider plain></Divider>
            <Paragraph style={{textAlign:'left'}}>
              We welcome you to our exclusive one-of-a-kind Web3 community. 
            </Paragraph>
            <Paragraph style={{textAlign:'left'}}>
              Please follow the simple steps to connect your wallet and register yourself with CryptoLulu which will unlock your unique token benefits, event information and upcoming announcements. 
            </Paragraph>
            <Paragraph style={{textAlign:'left'}}>
              Click here and get social with us
               <Link href="https://linktr.ee/cryptolulu" target={"_blank"}> https://linktr.ee/cryptolulu</Link>
            </Paragraph>
            <Paragraph style={{textAlign:'left'}}>
              For any questions or queries please contact is at <Link href="mailto:cryptoLulu@barlulu.com.au">cryptoLulu@barlulu.com.au</Link>
            </Paragraph>
            <Divider plain></Divider>
            <Button 
              style={{
                position: "absolute",
                right: 20,
                width: "120px",
                transform: "translateY(-50%)"
              }} 
              type="primary" 
              onClick={web3Login}
            >
                Wallet Login
            </Button>
            {isLoading ? (<Spin indicator={antIcon} />) : <></>}
          </Card>
        </Col>,
    },
    {
      title: 'Personal Data',
      content: <Col span={spanValue} offset={offsetValue}><FormCustomer goPrevious={prev} setCustomerData={captureCustomerData} tokenData={tokenIdData} customerData={customerData} /></Col>,
    },
    {
      title: 'Confirmation',
      content: <Col span={spanValue} offset={offsetValue}><FormConfirmation goNext={next} goPrevious={prev} tokenData={tokenIdData} customerData={customerData} send={send} /></Col>,
    },
    {
      title: 'Done',
      content: 
        <Col span={spanValue} offset={offsetValue}><Result
          status="success"
          title="Successfully submitted your registration!"
        />
        {/* <Divider plain></Divider>
            <Button 
              style={{
                position: "absolute",
                left: 0,
                width: "120px",
                transform: "translateY(-50%)"
              }} 
              type="primary" 
              onClick={prev}
            >
                Back
            </Button> */}
        </Col>
        ,
    },
  ];
  

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const contentStyle: React.CSSProperties = {
    lineHeight: '260px',
    textAlign: 'center',
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  return (
    <>
      {contextHolder}
      <Layout className="layout">
        <Header
          style={{
            backgroundColor: '#91573b'
          }}
        >
          <img 
            src='../Logo.png'
            style={{ 
              marginTop:5,
              height: '50px',
            }}
          >
          </img>
          <img 
            src='../Name2.png'
            style={{ 
              marginBottom:0,
              marginLeft:0,
              height: '45px'
            }}
          >
          </img>
        </Header>
        <Content style={{ padding: isMobile ? '' : '0 50px' }}>
          <br></br>
          <Steps current={current} items={items} />
          <div style={contentStyle}>{steps[current].content}</div>
        </Content>
      </Layout>
    </>
  );
};

export default App;